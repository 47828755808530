.durationControls{
	position: relative;
	z-index: 1;
}
.playPauseButton{
	vertical-align: middle;
  float: left;
  width: 20%;
  margin-top: 40%;
  margin-left: 40%;
  height: 20%;
  clear: both;
  border-radius: 100%;
  cursor: pointer;
}

@media (orientation: landscape) {

	.playPauseButton{
		font-size: 5vw;
	  line-height: 5vw;
	}
	.durationControls{
		width: 25vw;
		height: 25vw;
		border-radius: 25vw;
	}

}

@media (orientation: portrait) {

	.playPauseButton{
		font-size: 5vh;
    line-height: 5vh;
	}
	.durationControls{
		width: 25vh;
		height: 25vh;
		border-radius: 25vh;
	}

}

svg#bins{
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.duration{
	width: 100%;
	float: left;
	text-align: center;
	margin-top: 20px;
	font-weight: 100;
	color: #ccc;
}

@keyframes pulse 
{       
  0% { color: #c00; opacity:1.0; /*transform: scale(1.0);*/ }
  50% { color: #333; opacity:0.0; /*transform: scale(0.5);*/  }
  100% { color: #c00; opacity:1.0; /*transform: scale(1.0);*/ }
}
.fa-circle.recording{
	color: #c00;
}
.pulse
{
  /* Giving Animation Function */
  animation: pulse 2s linear infinite;
}

circle.recordCircle:not(.visible),path.arc:not(.visible){
	display: none;
	opacity: 0;
}