body {
  margin: 0;
  padding: 0;
  background: #111;
  color: #eee;
  font-family: Helvetica, Arial, Sans-serif;
}

html, body, #app {
  height: 100%
}

body > #root{
  height: 100%;
}