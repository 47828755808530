.recordings{
	position: absolute;
	bottom: 5px;
	left: 0px;
	align-self: flex-end;
	width: 100%;
}
.checkbox{
	margin-right: 5px;
	color: #00cc00;
}
.playback{
	margin: 0px 0px 0px 5px;
	border-radius: 0px 5px 5px 0px;
	width: 10px;
	height: 10px;
	padding: 5px 10px;
	background: #00cc00;
	cursor: pointer;
}
.track.isRecording .playback{
	background: #cccccc;
}
.trackList{
	float: left;
	width: calc(100% - 10px);
	margin-left: 5px;
}
.track{
	float: left;
    background: rgba(255, 255, 255, 0.1);
    margin: 5px;
    padding: 5px 0px 5px 10px;
    border-radius: 5px;
    cursor: pointer;
}

.save{
	line-height: 50px;
    background: rgba(255, 255, 255, 0.1);
    margin: 5px 10px 5px 10px;
    border-radius: 5px;
    float: left;
    width: calc(100% - 20px);
    color: #0c0;
    cursor: pointer;
}