#App{
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

#notes{
  float: left;
  position: absolute;
  top: 5px;
  left: 5px;
  width: calc(100% - 10px);
  font-weight: 100;
  text-transform: uppercase;
  font-size: 12px;
}