#micPermissions{
	background: rgba(0,0,0,0.8);
	position: absolute;
	left: 0;
	top: 0;
	z-index: 2;
	width: 100%;
	height: 100%;
	text-align: center;

	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
}
#micPermissions.hidden{
	display: none;
}

.allowMicPermissions{
	
	margin:  0 auto;
	vertical-align: middle;
	
	background: #333;
	text-align: center;	
	font-weight: 100;
	border: 1px solid #aaa;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
}

@media (orientation: landscape) {
	.allowMicPermissions{
		width: 50vh;
		height: 50vh;
		border-radius: 50vh;
	}
}

@media (orientation: portrait) {
	.allowMicPermissions{
		width: 50vw;
		height: 50vw;
		border-radius: 50vw;
	}
}
